import React from 'react';
import { getSrc } from 'gatsby-plugin-image';
import { Box, useBreakpointValue, useToken } from '@chakra-ui/react';
import tinycolor from 'tinycolor2';
import { useLocation } from '@reach/router';
import Preload from '../links/preload';
import calculateImageType from '../../utils/image-type';

const getBackgroundImageValue = ({ defaultBgColour, bgColour, bgSrc }) => {
  if (bgColour === null) {
    return `url('${bgSrc}')`;
  }
  const bgColourWithGradient = tinycolor(bgColour || defaultBgColour)
    .setAlpha(0.85)
    .toRgbString();
  return `linear-gradient(${bgColourWithGradient}, ${bgColourWithGradient}), url('${bgSrc}')`;
};

const BgImage = ({ bg, bgColour, preload, adaptiveLoading, ...props }) => {
  const darkBlue = useToken('colors', 'tt.darkBlue');
  const { origin } = useLocation();
  const bgSrc = getSrc(bg, origin);
  const bgSrcType = calculateImageType(bgSrc);
  const showImageBg =
    adaptiveLoading === true ? useBreakpointValue([false, true]) : true;

  return (
    <>
      {preload && bgSrcType && showImageBg && (
        <Preload url={bgSrc} as="image" type={bgSrcType} />
      )}
      <Box
        as="section"
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        position="relative"
        opacity="0.99"
        _before={{
          zIndex: -100,
          backgroundImage: getBackgroundImageValue({
            defaultBgColour: darkBlue,
            bgColour,
            bgSrc: showImageBg ? bgSrc : bg?.placeholder?.fallback,
          }),
          opacity: 1,
          content: "''",
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          backgroundColor: '#164194',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-Repeat',
          backgroundSize: 'cover',
        }}
        {...props}
      />
    </>
  );
};

export default BgImage;
